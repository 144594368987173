import Swal from 'sweetalert2';
import { deletedForm, deleteMasterCompany, deleteMasterProject, deleteUser } from '../API'
const SweetalertConfirm = async (data) => {

    let modulAPi
    switch (data?.type) {
        case 'deletedForm':
            modulAPi = deletedForm
            break;
        case 'deletedCompany':
            modulAPi = deleteMasterCompany
            break;
        case 'deletedProject':
            modulAPi = deleteMasterProject
            break;
        case 'logout':
            modulAPi = 'logout'
            break;
        case 'deletedusers':
            modulAPi = deleteUser
            break;
        default:
            break;
    }

    Swal.fire({
        title: "Are you sure?",
        text: "You won't to delete this data",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#579ec5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
        if (result.isConfirmed) {

            try {
                const postDelete = await modulAPi({ token: data?.token, dataId: data?.dataId })

                // Handle success response and refresh the page
                Swal.fire({
                    title: "Deleted!",
                    text: "Your data has been deleted.",
                    icon: "success"
                }).then(() => {
                    window.location.reload(); // Refresh the page
                });
            } catch (error) {
                // Handle error response
                Swal.fire({
                    title: "Error!",
                    text: "Something went wrong. Your data could not be deleted.",
                    icon: "error"
                });
                console.error("API call error:", error);
            }

        }
    });
};

export default SweetalertConfirm;
