
import Swal from 'sweetalert2'
import React, { useEffect, useState } from 'react';
import { redirect, useNavigate } from 'react-router-dom';
import { postLogin } from '../API'
import Sweetalert from '../component/Sweetalert'
const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const storedUserProfile = JSON.parse(localStorage.getItem('auth'));
    const token = storedUserProfile?.access_token;

    useEffect(() => {
        if (username && password) {
            setBtnDisabled(false);
        } else {
            setBtnDisabled(true);
        }
        if (token) {
            // Jika token ada, arahkan ke dashboard
            navigate('/dashboard');
        }
    }, [token, username, password]);

    const requestLogin = async () => {
        const payloadSend = {
            username: username,
            password: password,
        }
        try {
            const reqLogin = await postLogin(payloadSend)

            if (reqLogin?.data?.message === 'success') {
                localStorage.setItem('auth', JSON.stringify(reqLogin?.data?.data))
                Sweetalert({ icon: 'success', title: 'Login Success' });

                if (reqLogin?.data?.data.roles.roles_name === 'superadmin') {
                    console.log('admin')
                    navigate('/dashboard_admin');
                } else {
                    console.log('maker')
                    navigate('/dashboard');
                }
            } else {
                Sweetalert({ icon: 'error', title: reqLogin?.data?.message || 'Login failed' });
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.status === 400) {
                    Sweetalert({ icon: 'error', title: error.response.data.message });
                } else {
                    Sweetalert({ icon: 'error', title: `Error: ${error.response.status}` });
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.log('No response received: ', error.request);
                Sweetalert({ icon: 'error', title: 'No response from server' });
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error in request setup: ', error.message);
                Sweetalert({ icon: 'error', title: 'Request setup error' });
            }
        }
    }



    return (
        <body className="authentication-bg" style={{ backgroundColor: '#057eb3' }}>
            <div className="home-center">
                <div className="home-desc-center">

                    <div className="container">

                        <div className="home-btn"><a href="require{https://themesdesign.in/}" className="text-white router-link-active">
                        </a></div>


                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-6 col-xl-5">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="px-2 py-3">


                                            <div className="text-center">
                                                <a >
                                                    <img src="assets/images/schemalogo.png" alt="" height="20" />
                                                </a>

                                                <h5 className="text-bold mb-2 mt-4">Data Managent System</h5>

                                            </div>


                                            <form className="form-horizontal mt-4 pt-2" >

                                                <div className="mb-3">
                                                    <label for="username">Username</label>
                                                    <input type="text" className="form-control" id="username"
                                                        placeholder="Enter username"
                                                        onChange={(valText) => setUsername(valText.target.value)} />
                                                </div>

                                                <div className="mb-3">
                                                    <label for="userpassword">Password</label>
                                                    <input type="password" className="form-control" id="userpassword"
                                                        placeholder="Enter password"
                                                        onChange={(valText) => setPassword(valText.target.value)}
                                                    />
                                                </div>


                                                <div>
                                                    <a className={`btn  w-100 waves-effect waves-light  ${username && password ? 'block' : 'disabled'} btn-outline-info`}
                                                        onClick={() => requestLogin()}>Log In</a>
                                                </div>

                                                {/* <div className="mt-4 text-center">
                                                    <a href="auth-recoverpw.html" className="text-muted"><i className="mdi mdi-lock me-1"></i> Forgot your password?</a>
                                                </div> */}


                                            </form>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>


                </div>

            </div>
        </body>
    )
}

export default Login;