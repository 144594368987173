import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import DashboardAdmin from './pages/DashboardAdmin';
import PrivateRoute from './component/PrivateRoute';
import CreateForm from './pages/CreaterForm';
import DetailForm from './pages/DetailForm';
import DetailFormAdmin from './pages/DetailFormAdmin';
import MasterCompany from './pages/MasterCompany';
import MasterProject from './pages/MasterProject';
import MasterUser from './pages/MasterUser';


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} />
        <Route path="/dashboard_admin" element={<PrivateRoute element={DashboardAdmin} />} />
        <Route path="/createform" element={<PrivateRoute element={CreateForm} />} />
        <Route path="/detailform/:form_id" element={<PrivateRoute element={DetailForm} />} />
        <Route path="/admin/detailform/:form_id" element={<PrivateRoute element={DetailFormAdmin} />} />
        <Route path="/master/company" element={<PrivateRoute element={MasterCompany} />} />
        <Route path="/master/project" element={<PrivateRoute element={MasterProject} />} />
        <Route path="/master/user" element={<PrivateRoute element={MasterUser} />} />
      </Routes>
    </Router>
  );
};

export default App;
