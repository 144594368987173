import React, { useState, useEffect } from 'react';

const CurrentInput = ({ isChange, data, readOnly }) => {
    const [displayValue, setDisplayValue] = useState(''); // untuk menampilkan nilai terformat
    const [actualValue, setActualValue] = useState(''); // untuk menyimpan nilai asli tanpa format

    const formatRupiah = (number) => {
        // Menghapus semua karakter selain angka
        const numberString = number.replace(/[^,\d]/g, '').toString();
        const split = numberString.split(',');
        const remainder = split[0].length % 3;
        let rupiah = split[0].substr(0, remainder);
        const thousands = split[0].substr(remainder).match(/\d{3}/gi);

        // Memasukkan titik setiap ribuan
        if (thousands) {
            const separator = remainder ? '.' : '';
            rupiah += separator + thousands.join('.');
        }

        rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
        return 'Rp ' + rupiah;
    };

    const handleChange = (e) => {
        const inputValue = e.target.value.replace(/[^0-9]/g, ''); // Hanya menyimpan angka
        setActualValue(inputValue); // Simpan nilai asli

        const formattedValue = formatRupiah(inputValue); // Format untuk ditampilkan
        setDisplayValue(formattedValue);

        isChange(inputValue); // Kirim nilai asli tanpa format ke parent
    };


    useEffect(() => {
        if (data) {
            setDisplayValue(formatRupiah(data));
        }
    }, [data]);
    return (
        <input
            className="form-control"
            type="text"
            placeholder="Masukkan Nominal"
            value={displayValue} // Menampilkan nilai terformat
            onChange={handleChange}
            readOnly={readOnly === 'true'}
        />
    );
};

export default CurrentInput;
