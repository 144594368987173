import React, { useEffect, useState } from 'react';
import { redirect, useNavigate } from 'react-router-dom';
import { getMasterAllAdmin, createForm, detailFormAdmin, deleteByModul, updateForm, updateFileById, updateTitleById } from '../API'
import Sweetalert from './Sweetalert'
import Line from './Line'
import { Link } from 'react-router-dom';
import CurrentInput from './CurrentInput'
const MainFormDetailDataAdmin = (params) => {
    const navigate = useNavigate();
    const storedUserProfile = JSON.parse(localStorage.getItem('auth'));
    const token = storedUserProfile.access_token

    const [masterdata, setmasterdata] = useState([]);
    const [datadetail, setDatadetail] = useState(null);
    const [loopcomponentcontract, setLoopcomponentcontract] = useState(1)
    const [loopcomponentpurchaseorder, setLoopcomponentpurchaseorder] = useState(1)
    const [loopcomponenttermofpayment, setLoopcomponenttermofpayment] = useState(1)
    const [loopcomponentinvoice, setLoopcomponentinvoice] = useState(1)
    const [loopcomponentaddition, setLoopcomponentaddition] = useState(1)
    const [companyid, setCompanyid] = useState(null)
    const [projectid, setProjectid] = useState(null)
    const [status, setStatus] = useState(null)
    const [file, setFile] = useState(null);
    const [titlecontract, setTitlecontract] = useState([])
    const [titlepurchaseorder, setTitlepurchaseorder] = useState([])
    const [titleinvoice, setTitleinvoice] = useState([])
    const [titleadditional, setTitleadditional] = useState([])
    const [percentage, setPercentage] = useState([])
    const [amount, setAmount] = useState([])
    const [filecontract, setFilecontract] = useState([null]);
    const [filepurchaseorder, setFilepurchaseorder] = useState([null]);
    const [fileinvoice, setFileinvoice] = useState([null]);
    const [fileaddtinal, setFileadditional] = useState([null]);
    const [countpercentage, setcountPercentage] = useState(0);
    const [countamount, setCountamount] = useState(0);
    const [titleNew, setTitleNew] = useState(null);

    const formatRupiah = (number) => {
        // Menghapus semua karakter selain angka
        const numberString = number.toString();
        const split = numberString.split(',');
        const remainder = split[0].length % 3;
        let rupiah = split[0].substr(0, remainder);
        const thousands = split[0].substr(remainder).match(/\d{3}/gi);

        // Memasukkan titik setiap ribuan
        if (thousands) {
            const separator = remainder ? '.' : '';
            rupiah += separator + thousands.join('.');
        }

        rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
        return 'Rp ' + rupiah;
    };

    const fetchMaster = async () => {
        const getData = await getMasterAllAdmin({ token: token })
        // console.log(getData)
        if (getData?.data?.message === 'success') {
            setmasterdata(getData?.data?.data)
        } else {
            Sweetalert({ icon: 'error', title: getData?.data?.message || 'Get Failed' });
        }
    }

    const fetchDetail = async () => {
        const getData = await detailFormAdmin({ token: token, formId: params.formId })
        // console.log(getData)
        if (getData?.data?.message === 'success') {
            const contractData = getData?.data?.data?.contract
            const countPurchase = getData?.data?.data?.purchase_order
            const countTermOfPayment = getData?.data?.data?.term_of_payment
            const countInvoice = getData?.data?.data?.invoice
            const countAdditional = getData?.data?.data?.additional
            if (contractData.length > 0) {
                setLoopcomponentcontract(contractData.length)
            }
            if (countPurchase.length > 0) {
                setLoopcomponentpurchaseorder(countPurchase.length)
            }
            if (countTermOfPayment.length > 0) {

                const percentages = countTermOfPayment.map(item => item.percentage);
                const amounts = countTermOfPayment.map(item => item.amount);
                // Set state with arrays
                setPercentage(percentages);
                setAmount(amounts);

                setcountPercentage(percentages.reduce((acc, value) => acc + Number(value), 0));
                setCountamount(amounts.reduce((acc, value) => acc + Number(value), 0));
                setLoopcomponenttermofpayment(countTermOfPayment.length);
            }
            if (countInvoice.length > 0) {
                setLoopcomponentinvoice(countInvoice.length)
            }
            if (countAdditional.length > 0) {
                setLoopcomponentaddition(countAdditional.length)
            }
            setDatadetail(getData?.data?.data)


            // loopcomponentcontract
        } else {
            Sweetalert({ icon: 'error', title: getData?.data?.message || 'Get Failed' });
        }
    }


    // set array title contract
    const handleTitleContactChange = (index, event) => {
        const newTitles = [...titlecontract];
        newTitles[index] = event.target.value;
        setTitlecontract(newTitles);
    };

    // set array title purchase order
    const handleTitlePurchaseOrderChange = (index, event) => {
        const newTitles = [...titlepurchaseorder];
        newTitles[index] = event.target.value;
        console.log(index)
        setTitlepurchaseorder(newTitles);
    };

    // set array title percentage
    const handlePercentageChange = (index, event) => {
        const newTitles = [...percentage];
        newTitles[index] = event.target.value;
        setPercentage(newTitles);
        setcountPercentage(newTitles.reduce((accumulator, value) => accumulator + Number(value), 0))
    }

    const handleAmountChange = (index, event) => {
        const newTitles = [...amount];
        newTitles[index] = event;
        // console.log(newTitles, 'newTitles')
        setAmount(newTitles);
        setCountamount(newTitles.reduce((accumulator, value) => accumulator + Number(value), 0))
    };

    const handleAddtionalChange = (index, event) => {
        const newTitles = [...titleadditional];
        newTitles[index] = event.target.value;
        setTitleadditional(newTitles);

    };

    const handleInvoiceChange = (index, event) => {
        const newTitles = [...titleinvoice];
        newTitles[index] = event.target.value;
        setTitleinvoice(newTitles);

    };

    const handleFileUploadContract = (index, event) => {
        const newFiles = [...filecontract];
        newFiles[index] = event.target.files[0];
        setFilecontract(newFiles);
    };

    const handleFileUploadPurchaseOrder = (index, event) => {
        const newFiles = [...filepurchaseorder];
        newFiles[index] = event.target.files[0];
        setFilepurchaseorder(newFiles);
    };

    const handleFileUploadInvoice = (index, event) => {
        const newFiles = [...fileinvoice];
        newFiles[index] = event.target.files[0];
        setFileinvoice(newFiles);
    };
    const handleFileUploadAdditional = (index, event) => {
        const newFiles = [...fileaddtinal];
        newFiles[index] = event.target.files[0];
        setFileadditional(newFiles);
    };

    const handleUpdate = async () => {

        let formData = new FormData();

        formData.append('company_id', companyid);
        formData.append('project_id', projectid);

        for (let a = 0; a < titlepurchaseorder.length; a++) {
            formData.append(`purchase_order_title[${a}]`, titlepurchaseorder[a]);
            formData.append('purchase_order_file', filepurchaseorder[a]);
        }

        for (let b = 0; b < titlecontract.length; b++) {
            formData.append(`contract_title[${b}]`, titlecontract[b]);
            formData.append('contract_file', filecontract[b]);
        }

        for (let c = 0; c < titleinvoice.length; c++) {
            formData.append(`invoice_title[${c}]`, titleinvoice[c]);
            formData.append('invoice_file', fileinvoice[c]);
        }

        for (let d = 0; d < titleadditional.length; d++) {
            formData.append(`additional_title[${d}]`, titleadditional[d]);
            formData.append('additional_file', fileaddtinal[d]);
        }


        for (let e = 0; e < percentage.length; e++) {
            formData.append(`termin_percentage[${e}]`, percentage[e]);
            formData.append(`termin_amount[${e}]`, amount[e]);
        }

        formData.append('status', status);
        try {
            const postData = await updateForm({ token: token, payload: formData, formId: params.formId })

            if (postData?.data?.message === 'success') {
                Sweetalert({ icon: 'success', title: 'Create Data Success' });
                setCompanyid(null)
                setProjectid(null)
                setStatus(null)
                setTitlecontract([])
                setTitlepurchaseorder([])
                setTitleinvoice([])
                setTitleadditional([])
                setPercentage([])
                setAmount([])
                setFilecontract([null]);
                setFilepurchaseorder([null]);
                setFileinvoice([null]);
                setFileadditional([null]);
                setcountPercentage(0);
                setCountamount(0);
                navigate('/dashboard');
            } else {
                Sweetalert({ icon: 'error', title: postData?.data?.message || 'Create Datafailed' });
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.status === 400) {
                    Sweetalert({ icon: 'error', title: error.response.data.message });
                } else {
                    Sweetalert({ icon: 'error', title: `Error: ${error.response.status}` });
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.log('No response received: ', error.request);
                Sweetalert({ icon: 'error', title: 'No response from server' });
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error in request setup: ', error.message);
                Sweetalert({ icon: 'error', title: 'Request setup error' });
            }
        }

    }

    // delete per baris / yang sudah ada data
    const handleDeleteRow = async (data) => {

        try {
            const deletedDatas = await deleteByModul({ token: token, modul: data?.modul, data_id: data?.dataId, form_id: datadetail?.id })

            if (deletedDatas?.data?.message === 'success') {
                Sweetalert({ icon: 'success', title: 'Delete Data Success' });
                fetchDetail()
                navigate(`/detailform/${datadetail.id}`);
            } else {
                Sweetalert({ icon: 'error', title: deletedDatas?.data?.message || 'Delete Data failed' });
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.status === 400) {
                    Sweetalert({ icon: 'error', title: error.response.data.message });
                } else {
                    Sweetalert({ icon: 'error', title: `Error: ${error.response.status}` });
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.log('No response received: ', error.request);
                Sweetalert({ icon: 'error', title: 'No response from server' });
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error in request setup: ', error.message);
                Sweetalert({ icon: 'error', title: 'Request setup error' });
            }
        }
    }

    // update file per baris / yang sudah ada data
    const handleFileUpdate = async (data) => {

        let formData = new FormData();

        if (data?.modul === 'contact') {
            formData.append('contract_file', data.file.target.files[0]);
        } else if (data?.modul === 'invoice') {
            formData.append('invoice_file', data.file.target.files[0]);
        } else if (data?.modul === 'purchase_order') {
            formData.append('purchase_order_file', data.file.target.files[0]);
        } else if (data?.modul === 'additional_document') {
            formData.append('additional_document_file', data.file.target.files[0]);

        }
        try {
            const postUpdate = await updateFileById({ token: token, dataId: data?.id, formId: datadetail?.id, payload: formData, modul: data?.modul })

            if (postUpdate?.data?.message === 'success') {
                Sweetalert({ icon: 'success', title: 'Update Data Success' });
                fetchDetail()
                navigate(`/detailform/${datadetail.id}`);
            } else {
                Sweetalert({ icon: 'error', title: postUpdate?.data?.message || 'Update Data failed' });
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.status === 400) {
                    Sweetalert({ icon: 'error', title: error.response.data.message });
                } else {
                    Sweetalert({ icon: 'error', title: `Error: ${error.response.status}` });
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.log('No response received: ', error.request);
                Sweetalert({ icon: 'error', title: 'No response from server' });
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error in request setup: ', error.message);
                Sweetalert({ icon: 'error', title: 'Request setup error' });
            }
        }




    }

    const handleTitleUpdate = async (data) => {
        setTitleNew(data.title?.target?.value)
        const payload = {
            title: data.title?.target?.value
        }

    }



    const renderFileInputsContract = (data) => {
        const inputs = [];
        const contractDatas = data?.contract
        for (let a = 0; a < loopcomponentcontract; a++) {
            inputs.push(
                <div className='row mb-6' key={a}>
                    <div className='col-md-6'>
                        <input type='text' readOnly className='form-control' value={contractDatas?.[a]?.title}
                            onChange={(event) => {
                                handleTitleContactChange(a - contractDatas?.length, event)
                                if (contractDatas?.[a]?.title) {
                                    handleTitleUpdate({ id: contractDatas?.[a]?.id, title: event, modul: 'contract' })
                                }
                            }

                            } placeholder='title' />
                    </div>

                    {contractDatas?.[a]?.file && (
                        <div className='col-md-1 d-flex justify-content-between p-1'>
                            <a href={contractDatas?.[a]?.file ? encodeURI(contractDatas[a].file) : '#'} target='_blank' rel='noopener noreferrer' className=' btn btn-outline-info btn-sm m-auto'>
                                <i className='fa fa-file'></i>
                            </a>

                        </div>
                    )}

                </div >
            );
        }
        return inputs;
    };
    const renderFileInputsPurchaseOrder = (data) => {
        const inputsPurchase = [];
        const purchaseDatas = data?.purchase_order
        for (let b = 0; b < loopcomponentpurchaseorder; b++) {
            inputsPurchase.push(
                <div className='row mb-3' key={b}>
                    <div className='col-md-6'>
                        <input type='text' readOnly className='form-control' value={purchaseDatas?.[b]?.title} onChange={(event) => handleTitlePurchaseOrderChange(b - purchaseDatas.length, event)} placeholder='title' />
                    </div>
                    {purchaseDatas?.[b]?.file && (

                        <div className='col-md-1 d-flex justify-content-between p-1'>
                            <a href={purchaseDatas?.[b]?.file ? encodeURI(purchaseDatas[b].file) : '#'} target='_blank' rel='noopener noreferrer' className=' btn btn-outline-info btn-sm m-auto'>
                                <i className='fa fa-file'></i>
                            </a>
                        </div>
                    )}

                </div>
            );
        }
        return inputsPurchase;
    };
    const renderFileInputsTermOfPayment = (data) => {
        const inputsTermOfPayment = [];
        const paymentDatas = data?.term_of_payment
        for (let c = 0; c < loopcomponenttermofpayment; c++) {
            inputsTermOfPayment.push(
                <>
                    <div className='row mb-3' key={c}>
                        <div className='col-md-1'>
                            <label for="validationCustom02" class="form-label p-1">{`Termin ${c + 1}`}</label>
                        </div>
                        <div className={paymentDatas?.[c] ? 'col-md-4' : 'col-md-5'}>
                            <input type='number' readOnly min={1} max={100} className='form-control' value={paymentDatas?.[c]?.percentage} placeholder='percentage'
                                onChange={(event) => {
                                    handlePercentageChange(c, event)
                                }
                                } />
                        </div>
                        <div className='col-md-5'>
                            <CurrentInput
                                isChange={(newValue) => handleAmountChange(c, newValue)}
                                data={paymentDatas?.[c]?.amount}
                                readOnly='true'
                            />
                        </div>

                        {/* <input type='number' className='form-control' value={paymentDatas?.[c]?.amount} placeholder='amount' onChange={(event) => handleAmountChange(c, event)} /> */}



                    </div>
                </>
            );
        }
        return inputsTermOfPayment;
    };
    const renderFileInputsInvoice = (data) => {
        const inputsInvoice = [];
        const invoiceDatas = data?.invoice
        for (let d = 0; d < loopcomponentinvoice; d++) {
            inputsInvoice.push(
                <div className='row mb-3' key={d}>
                    <div className='col-md-6'>
                        <input type='text' readOnly className='form-control' value={invoiceDatas?.[d]?.title} onChange={(event) => handleInvoiceChange(d - invoiceDatas?.length, event)} placeholder='title' />
                    </div>

                    {invoiceDatas?.[d]?.file && (
                        <div className='col-md-1 d-flex justify-content-between p-1'>
                            <a href={invoiceDatas?.[d]?.file ? encodeURI(invoiceDatas[d].file) : '#'} target='_blank' rel='noopener noreferrer' className=' btn btn-outline-info btn-sm m-auto'>
                                <i className='fa fa-file'></i>
                            </a>
                        </div>

                    )}

                </div>
            );
        }
        return inputsInvoice;
    };
    const renderFileInputsAddition = (data) => {
        const inputsAddition = [];
        const additionalDatas = data?.additional
        for (let e = 0; e < loopcomponentaddition; e++) {
            inputsAddition.push(
                <div className='row mb-3' key={e}>
                    <div className='col-md-6'>
                        <input type='text' readOnly className='form-control' value={additionalDatas?.[e]?.title} onChange={(event) => handleAddtionalChange(e - additionalDatas?.length, event)} placeholder='title' required />
                    </div>
                    {additionalDatas?.[e]?.file && (
                        <div className='col-md-1 d-flex justify-content-between p-1'>
                            <a href={additionalDatas?.[e]?.file ? encodeURI(additionalDatas[e].file) : '#'} target='_blank' rel='noopener noreferrer' className=' btn btn-outline-info btn-sm m-auto'>
                                <i className='fa fa-file'></i>
                            </a>
                        </div>
                    )}

                </div>
            );
        }
        return inputsAddition;
    };

    useEffect(() => {
        fetchMaster()
        fetchDetail()

    }, [])

    return (
        <div class="main-content">
            <div className="page-content">
                <div className="page-title-box">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <div className="page-title">
                                    <h4>Form</h4>
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <a href="javascript: void(0);">DMS</a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript: void(0);">Edit Data</a>
                                        </li>

                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="page-content-wrapper">
                        <div class="col-xl-12">
                            <div class="card">
                                <div class="card-body">
                                    <form class="needs-validation" novalidate>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label for="validationCustom01" class="form-label">Company Name</label>
                                                    <select class="form-select" disabled onChange={(e) => setCompanyid(e.target.value)} required>
                                                        {masterdata?.company_list?.map((valCompany) =>
                                                            <option
                                                                selected={datadetail?.company_name === valCompany?.company_name ? true : false}
                                                                value={valCompany?.id} >{valCompany?.company_name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label for="validationCustom02" class="form-label">Project Name</label>
                                                    <select class="form-select" disabled onChange={(e) => setProjectid(e.target.value)} required>
                                                        {masterdata?.project_list?.map((valProject) =>
                                                            <option
                                                                selected={datadetail?.project_name === valProject?.project_name ? true : false}
                                                                value={valProject?.id} >{valProject?.project_name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <Line />

                                        <label for="validationCustom01" class="form-label">Contract File</label>
                                        {renderFileInputsContract(datadetail)}

                                        <Line />

                                        <label for="validationCustom01" class="form-label">Purchase Order File</label>
                                        {renderFileInputsPurchaseOrder(datadetail)}

                                        <Line />

                                        <label for="validationCustom01" class="form-label">Term Of Payment</label>
                                        {renderFileInputsTermOfPayment(datadetail)}

                                        <div className='row'>
                                            <div className='col-md-1'>
                                                <label for="validationCustom02" className="form-label p-1 mb-3">Total</label>
                                            </div>
                                            <div className='col-md-5'>
                                                <input value={`${countpercentage}%`} className='form-control' readOnly />
                                            </div>
                                            <div className='col-md-5'>
                                                <input value={formatRupiah(countamount)} className='form-control' readOnly />
                                            </div>
                                        </div>

                                        <Line />

                                        <label for="validationCustom01" class="form-label">Invoice</label>
                                        {renderFileInputsInvoice(datadetail)}

                                        <Line />

                                        <label for="validationCustom01" class="form-label">Addition</label>
                                        {renderFileInputsAddition(datadetail)}

                                        <Line />
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <label for="validationCustom02" className="form-label p-1">Status</label>
                                                <select class="form-select" disabled onChange={(e) => setStatus(e.target.value)} required>
                                                    <option
                                                        selected={datadetail?.status === 'done' ? true : false}
                                                        value="done">Done</option>
                                                    <option
                                                        selected={datadetail?.status === 'on progress' ? true : false}
                                                        value="on progress">On Progress</option>
                                                </select>
                                            </div>
                                        </div>


                                        <div className='d-flex justify-content-end mt-3'>
                                            <button type="button" class="btn btn-outline-danger waves-effect waves-light mx-2" onClick={() => navigate('/dashboard_admin')}>Back</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MainFormDetailDataAdmin;