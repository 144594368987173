import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../component/Header';
import Footer from '../component/Footer';
import Sidebar from '../component/Sidebar';
import MainFormDetailData from '../component/MainFormDetailData';
const DetailForm = () => {
    const { form_id } = useParams();
    return (
        <div id="layout-wrapper" >
            <Header />
            <Sidebar />
            <MainFormDetailData formId={form_id} />
            <Footer />
        </div>
    )

}

export default DetailForm;