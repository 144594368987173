import React, { useEffect, useState } from 'react';
import { getMasterProjectForAdmin, syncFormAdmin } from '../API'
import Sweetalert from './Sweetalert'
import SweetalertConfirm from './SweetalertConfirm'
import PaginationComponent from './PaginationComponent'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
const MainDashboardAdmin = () => {
    const navigate = useNavigate();
    const storedUserProfile = JSON.parse(localStorage.getItem('auth'));
    const token = storedUserProfile.access_token

    const [data, setData] = useState([])
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState('10');

    const fetchList = async () => {
        const getData = await getMasterProjectForAdmin({ token: token, limit: limit, paginate: page })
        if (getData?.data?.message === 'success') {
            setData(getData?.data?.data)
        } else {
            Sweetalert({ icon: 'error', title: getData?.data?.message || 'Get Failed' });
        }
    }

    const handleDeleteFOrm = async (data) => {
        // const postDelete = await deletedForm({ token: token, formId: data })
        SweetalertConfirm({ type: 'deletedForm', token: token, dataId: data })
        fetchList()
    }

    const handleSynchronize = async () => {

        try {
            const syncData = await syncFormAdmin({ token: token })

            if (syncData?.data?.message === 'success') {
                Sweetalert({ icon: 'success', title: 'Create Data Success' });
                fetchList()
            } else {
                Sweetalert({ icon: 'error', title: syncData?.data?.message || 'Create Data failed' });
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.status === 400) {
                    Sweetalert({ icon: 'error', title: error.response.data.message });
                } else {
                    Sweetalert({ icon: 'error', title: `Error: ${error.response.status}` });
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.log('No response received: ', error.request);
                Sweetalert({ icon: 'error', title: 'No response from server' });
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error in request setup: ', error.message);
                Sweetalert({ icon: 'error', title: 'Request setup error' });
            }
        }
    }

    useEffect(() => {
        fetchList()
    }, [page, limit])


    return (
        <div class="main-content">
            <div className="page-content">
                <div className="page-title-box">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <div className="page-title">
                                    <h4>Data Project</h4>
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <a href="javascript: void(0);">DMS</a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript: void(0);">Data Project</a>
                                        </li>

                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="page-content-wrapper">

                        <div class="col-xl-12">
                            <div class="card">
                                <div class="card-body">
                                    <div className='d-flex justify-content-end'>
                                        <div className='d-flex justify-content-between'>
                                            <div className='p-2'>
                                                <a href='https://docs.google.com/spreadsheets/d/1DrJPLGG7hQqh6QJJ_A9uc_hdqo_7vKE7dJEB2WC89lk/edit?usp=sharing' target='_BLANK' className='btn btn-outline-success btn-sm mb-2' onClick={handleSynchronize}>
                                                    <i className='mdi mdi-google-spreadsheet'></i> To Spreadsheet
                                                </a>
                                            </div>
                                            <div className='p-2'>
                                                <a className='btn btn-outline-danger btn-sm mb-2' onClick={handleSynchronize}><i className='mdi mdi-sync'></i> Synchronize</a>
                                            </div>

                                        </div>
                                    </div>
                                    <table class="table table-bordered dt-responsive nowrap" style={{ borderCollapse: 'collapse', borderSpacing: '0', width: '100%' }}>
                                        <thead>
                                            <tr className='text-white text-center' style={{ backgroundColor: '#579ec5' }}>
                                                <th>No</th>
                                                <th>Company Name</th>
                                                <th>Project Name</th>
                                                <th>Contract</th>
                                                <th>Purchase Order</th>
                                                <th>Term Of Payment</th>
                                                <th>Invoice</th>
                                                <th>Additional Document</th>
                                                <th>Status</th>
                                                <th>Maker</th>
                                                <th>#</th>
                                            </tr>
                                        </thead>


                                        <tbody>
                                            {data?.form_list?.map((item, index) =>
                                                <tr>
                                                    <td>{(page - 1) * limit + index + 1}</td>
                                                    <td>{item?.company_name}</td>
                                                    <td>{item?.project_name}</td>
                                                    <td>{item?.contract}</td>
                                                    <td>{item?.purchase_order}</td>
                                                    <td>{item?.term_of_payment}</td>
                                                    <td>{item?.invoice}</td>
                                                    <td>{item?.additional}</td>
                                                    <td> {item?.status === 'done' ? (
                                                        <a className='btn btn-outline-success btn-sm'>Done</a>
                                                    ) : (
                                                        <a className='btn btn-outline-warning btn-sm'>Pending</a>
                                                    )}</td>
                                                    <td>{item.maker}</td>
                                                    <td>
                                                        <div className='d-flex justify-content-between'>
                                                            <Link to={`/admin/detailform/${item.id}`} className='btn btn-outline-info btn-sm'>  <i className='fas fa-eye-dropper'></i></Link>
                                                        </div>
                                                    </td>
                                                </tr>

                                            )}
                                        </tbody>
                                    </table>

                                    <div className='d-flex justify-content-end'>
                                        <PaginationComponent
                                            page={page}
                                            dataList={data}
                                            setPage={setPage}
                                            limit={limit}
                                            setLimit={setLimit}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default MainDashboardAdmin;