import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';



const Sidebar = () => {
    // const storedUserProfile = JSON.parse(localStorage.getItem('auth'));
    const [storedUserProfile, setStoredUserProfile] = useState(null);
    const [isSubmenuVisible, setSubmenuVisible] = useState(false);


    useEffect(() => {
        // Selalu membaca data terbaru dari localStorage saat komponen dimuat
        const userProfile = JSON.parse(localStorage.getItem('auth'));
        setStoredUserProfile(userProfile);
    }, []);

    return (
        <div className="vertical-menu">
            <div data-simplebar className="h-100">
                <div className="user-sidebar text-center">
                    <div className="dropdown">
                        <div className="user-img">
                            <img
                                src={storedUserProfile?.photo ? storedUserProfile?.photo : "/assets/images/users/avatar-7.jpg"}
                                alt="User Avatar"
                                className="rounded-circle"
                            />
                            <span className="avatar-online bg-success"></span>
                        </div>
                        <div className="user-info">
                            <h5 className="mt-3 font-size-16 text-white">{storedUserProfile?.username}</h5>
                            <span className="font-size-13 text-white-50">{storedUserProfile?.roles?.roles_name}</span>
                        </div>
                    </div>
                </div>

                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title ">Menu</li>

                        <li>
                            <Link to={storedUserProfile?.roles?.roles_name === 'superadmin' ? '/dashboard_admin' : '/dashboard'} className="waves-effect">
                                <i className="dripicons-home"></i>
                                <span>Dashboard</span>
                            </Link>
                        </li>

                        {/* menu maker */}
                        {storedUserProfile?.roles?.roles_name === 'maker' && (
                            <li className={`${isSubmenuVisible ? 'mm-active' : ''}`}>
                                <a href="javascript: void(0);" className="has-arrow waves-effect" aria-expanded="true" onClick={() => setSubmenuVisible(!isSubmenuVisible)}>
                                    <i className="ti-save"></i>
                                    <span>Master</span>
                                </a>
                                <ul className={`sub-menu mm-collapse${isSubmenuVisible ? 'mm-show' : ''}`} aria-expanded="true">
                                    <li>
                                        <Link to="/master/company" className="waves-effect">
                                            <span>Company</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/master/project" className="waves-effect">
                                            <span>Project</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        )}

                        {/* menu admin */}
                        {storedUserProfile?.roles?.roles_name === 'superadmin' && (
                            <li className={`${isSubmenuVisible ? 'mm-active' : ''}`}>
                                <a href="javascript: void(0);" className="has-arrow waves-effect" aria-expanded="true" onClick={() => setSubmenuVisible(!isSubmenuVisible)}>
                                    <i className="ti-save"></i>
                                    <span>Master</span>
                                </a>
                                <ul className={`sub-menu mm-collapse${isSubmenuVisible ? 'mm-show' : ''}`} aria-expanded="true">
                                    <li>
                                        <Link to="/master/user" className="waves-effect">
                                            <span>User</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;
