import React, { useEffect, useState } from 'react';
import { getMasterCompany, createMasterCompany, editMasterCompany } from '../API'
import Sweetalert from '../component/Sweetalert'
import ModelCreate from '../component/ModalCreate'
import ModalEdit from '../component/ModalEdit'
import SweetalertConfirm from '../component/SweetalertConfirm'
import PaginationComponent from '../component/PaginationComponent'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
const MainMasterCompany = () => {
    const navigate = useNavigate();
    const storedUserProfile = JSON.parse(localStorage.getItem('auth'));
    const token = storedUserProfile.access_token

    const [data, setData] = useState([])
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState('10');
    const [keysearch, setKeysearch] = useState('');
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [companyName, setCompanyName] = useState(null);
    const [companyDataEdit, setCompanyDataEdit] = useState(null);


    // fetch list
    const fetchList = async () => {
        const getData = await getMasterCompany({ token: token, limit: limit, paginate: page, keysearchs: keysearch })
        if (getData?.data?.message === 'success') {
            setData(getData?.data?.data)
        } else {
            Sweetalert({ icon: 'error', title: getData?.data?.message || 'Get Failed' });
        }
    }


    // delete row
    const handleDeleteMaster = async (data) => {
        SweetalertConfirm({ type: 'deletedCompany', token: token, dataId: data })
        fetchList()
    }

    // handle modal create
    const handleToggleModalCreate = () => {
        setIsModalCreateOpen(!isModalCreateOpen)
    }

    // handle modal edot
    const handleToggleModalEdit = (data) => {

        if (data) {
            setCompanyDataEdit(data)
        }
        setIsModalEditOpen(!isModalEditOpen)
    }

    // create row
    const handleSaveMaster = async () => {
        setIsModalCreateOpen(false)
        const payload = {
            company_name: companyName
        }
        try {
            const postMaster = await createMasterCompany({ token: token, payload: payload })

            if (postMaster?.data?.message === 'success') {
                Sweetalert({ icon: 'success', title: 'Create Data Success' });
                fetchList()
            } else {
                Sweetalert({ icon: 'error', title: postMaster?.data?.message || 'Create Data failed' });
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.status === 400) {
                    Sweetalert({ icon: 'error', title: error.response.data.message });
                } else {
                    Sweetalert({ icon: 'error', title: `Error: ${error.response.status}` });
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.log('No response received: ', error.request);
                Sweetalert({ icon: 'error', title: 'No response from server' });
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error in request setup: ', error.message);
                Sweetalert({ icon: 'error', title: 'Request setup error' });
            }
        }
        setCompanyName('')
    }

    // update row
    const handleEditMaster = async (data) => {

        const payload = {
            company_name: data
        }

        try {
            const updateMaster = await editMasterCompany({ token: token, payload: payload, dataId: companyDataEdit?.id })

            if (updateMaster?.data?.message === 'success') {
                Sweetalert({ icon: 'success', title: 'Create Data Success' });
                fetchList()
            } else {
                Sweetalert({ icon: 'error', title: updateMaster?.data?.message || 'Create Data failed' });
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.status === 400) {
                    Sweetalert({ icon: 'error', title: error.response.data.message });
                } else {
                    Sweetalert({ icon: 'error', title: `Error: ${error.response.status}` });
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.log('No response received: ', error.request);
                Sweetalert({ icon: 'error', title: 'No response from server' });
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error in request setup: ', error.message);
                Sweetalert({ icon: 'error', title: 'Request setup error' });
            }
        }

        setCompanyName(null)
        setIsModalEditOpen(false)
    }

    useEffect(() => {
        fetchList()
    }, [page, limit, keysearch])


    // console.log(companyDataEdit, 'companyDataEdit')

    return (
        <div class="main-content">
            <div className="page-content">
                <div className="page-title-box">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <div className="page-title">
                                    <h4>Data Project</h4>
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <a href="javascript: void(0);">DMS</a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript: void(0);">Data Project</a>
                                        </li>

                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="page-content-wrapper">

                        <div class="col-xl-12">
                            <div class="card">
                                <div class="card-body">
                                    <div className='d-flex justify-content-end m-auto'>
                                        <div className='p-2'>
                                            <div class="input-group input-group-sm" id="datepicker1">
                                                <input className='form-control-sm' placeholder='search' onChange={(event) => setKeysearch(event.target.value)} />

                                                <span class="input-group-text" style={{ backgroundColor: '#ff' }}><i class="mdi mdi-database-search"></i></span>
                                            </div>
                                        </div>
                                        <div className='p-2'>
                                            <a className='btn btn-outline-info btn-sm mb-2' onClick={() => handleToggleModalCreate()}>+ Create New Data</a>
                                        </div>
                                    </div>
                                    <table class="table table-bordered dt-responsive nowrap" style={{ borderCollapse: 'collapse', borderSpacing: '0', width: '100%' }}>
                                        <thead>
                                            <tr className='text-white text-center' style={{ backgroundColor: '#579ec5' }}>
                                                <th width='10'>No</th>
                                                <th>Company Name</th>
                                                <th>#</th>
                                            </tr>
                                        </thead>


                                        <tbody>
                                            {data?.master_list?.map((item, index) =>
                                                <tr>
                                                    <td>{(page - 1) * limit + index + 1}</td>
                                                    <td>{item?.company_name}</td>
                                                    <td>
                                                        <div className='d-flex justify-content-center'>
                                                            <a className='btn btn-outline-info btn-sm' onClick={() => { handleToggleModalEdit(item) }}>
                                                                <i className='fas fa-eye-dropper'></i>
                                                            </a>
                                                            <a className='btn btn-outline-danger btn-sm'
                                                                onClick={() => handleDeleteMaster(item.id)}
                                                            >
                                                                <i className='fas fa-trash-alt'></i>
                                                            </a>
                                                        </div>
                                                    </td>

                                                </tr>

                                            )}
                                        </tbody>
                                    </table>

                                    <div className='d-flex justify-content-end'>
                                        <PaginationComponent
                                            page={page}
                                            dataList={data}
                                            setPage={setPage}
                                            limit={limit}
                                            setLimit={setLimit}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal Component */}
                {isModalCreateOpen && (
                    <ModelCreate isOpen={isModalCreateOpen} onClose={handleToggleModalCreate} onSave={handleSaveMaster} setName={setCompanyName} />
                )}

                {isModalEditOpen && (
                    <ModalEdit isOpen={isModalEditOpen} onClose={handleToggleModalEdit} type='company' onSave={handleEditMaster} data={companyDataEdit} />
                )}


            </div>
        </div >


    )
}

export default MainMasterCompany;