import React, { useEffect, useState } from 'react';

const ModalEdit = ({ isOpen, onClose, onSave, type, data }) => {
    const [TemporaryName, setTemporaryName] = useState('');

    // Initialize local state with data when the modal opens
    useEffect(() => {
        if (data) {
            setTemporaryName(type == 'company' ? data.company_name : type == 'project' ? data.project_name : null);
        }
    }, [data]);

    const handleSave = () => {
        onSave(TemporaryName); // Directly pass the updated name back to the parent component
    };


    if (!isOpen) return null; // If modal is not open, return null to render nothing
    return (
        <>
            {/* Backdrop element */}
            <div className="modal-backdrop fade show"></div>

            {/* Modal */}
            <div className="modal fade show" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" style={{ display: 'block' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mt-0">Edit Data</h5>
                            <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <input type='text' required className='form-control' value={TemporaryName} placeholder='Company Name' onChange={(valText) => setTemporaryName(valText.target.value)} />

                            <div className='d-flex justify-content-end mt-3'>
                                <div className='d-flex justify-content-between'>
                                    <div className='p-1'>
                                        <a className='btn btn-sm btn-outline-success' onClick={handleSave}>save</a>
                                    </div>
                                    <div className='p-1'>
                                        <a className='btn btn-sm btn-outline-danger' onClick={onClose}> Cencel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default ModalEdit;
