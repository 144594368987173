import React from 'react';

const ModelCreateUsers = ({ isOpen, onClose, onSave, masterData, setUsername, setPassword, setPhoto, setRoles, setEmail }) => {
    if (!isOpen) return null; // If modal is not open, return null to render nothing

    return (
        <>
            {/* Backdrop element */}
            <div className="modal-backdrop fade show"></div>

            {/* Modal */}
            <div className="modal fade show" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" style={{ display: 'block' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mt-0">Create Data Users</h5>
                            <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <input type='text' required className='form-control mb-3' placeholder='Username' onChange={(valText) => setUsername(valText.target.value)} />
                            <input type='email' required className='form-control mb-3' placeholder='Email' onChange={(valText) => setEmail(valText.target.value)} />
                            <input type='file' required className='form-control mb-3' onChange={(event) => setPhoto(event.target.files[0])} />

                            <select class="form-select mb-3" onChange={(e) => setRoles(e.target.value)} required>
                                <option selected disabled value="">Choose...</option>
                                {masterData?.map((valRoles) =>
                                    <option value={valRoles?.id} >{valRoles?.roles_name}</option>
                                )}
                            </select>

                            <input type='text' required className='form-control mb-3' placeholder='Password' onChange={(valText) => setPassword(valText.target.value)} />

                            <div className='d-flex justify-content-end mt-3'>
                                <div className='d-flex justify-content-between'>
                                    <div className='p-1'>
                                        <a className='btn btn-sm btn-outline-success' onClick={onSave}>save</a>
                                    </div>
                                    <div className='p-1'>
                                        <a className='btn btn-sm btn-outline-danger' onClick={onClose}> Cencel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default ModelCreateUsers;
