import React, { useEffect, useState } from 'react';
const Footer = () => {
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">
                        <script>document.write(new Date().getFullYear())</script> © Skema Kongitif Indonsia.
                    </div>

                </div>
            </div>
        </footer>

    )
}

export default Footer;