import React from 'react';
import { Route, Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Component }) => {
    const storedUserProfile = JSON.parse(localStorage.getItem('auth'));
    const token = storedUserProfile?.access_token;

    // return token ? <Component {...rest} /> : <Navigate to="/" />;
    return token ? <Component /> : <Navigate to="/" />;
};

export default PrivateRoute;