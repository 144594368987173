import React, { useEffect, useState } from 'react';
const Line = () => {
    return (
        <div style={{
            height: "1px",
            backgroundColor: "#ccc",
            margin: "15px 0"
        }}></div>
    )
}

export default Line;