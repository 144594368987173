import React from 'react';

const PaginationComponent = ({ page, dataList, setPage, limit, setLimit }) => {
    return (
        <div className="row align-items-center">
            <div className="col">
                Showing Data {page} of {dataList?.paginate?.length} pages
            </div>
            <div className="col text-end d-flex justify-content-end">
                <div className="d-inline-flex">
                    <button
                        type="button"
                        className="mx-1 btn btn-sm btn-white waves-effect"
                        onClick={() => setPage(1)}
                    >
                        First
                    </button>
                    <button
                        type="button"
                        disabled={page === 1}
                        className="mx-1 btn btn-sm btn-white waves-effect"
                        onClick={() => setPage(page - 1)}
                    >
                        <i className="fa fa-chevron-left" />
                    </button>
                    {dataList?.paginate?.map((item, index) => {
                        if (page === 1 && index < 6) {
                            return (
                                <button
                                    key={index}
                                    type="button"
                                    className={'mx-1 btn btn-sm waves-effect ' + (item === page ? 'btn-info' : 'btn-white')}
                                    onClick={() => setPage(item)}
                                >
                                    {item}
                                </button>
                            );
                        } else if (page >= 2 && index >= page - 2 && index <= page + 3) {
                            return (
                                <button
                                    key={index}
                                    type="button"
                                    className={'mx-1 btn btn-sm waves-effect ' + (item === page ? 'btn-info' : 'btn-white')}
                                    onClick={() => setPage(item)}
                                >
                                    {item}
                                </button>
                            );
                        }
                        return null;
                    })}
                    <button
                        type="button"
                        disabled={page === dataList?.paginate?.length}
                        className="mx-1 btn btn-sm btn-white waves-effect"
                        onClick={() => setPage(page + 1)}
                    >
                        <i className="fa fa-chevron-right" />
                    </button>
                    <button
                        type="button"
                        className="mx-1 btn btn-sm btn-white waves-effect"
                        onClick={() => setPage(dataList?.paginate?.length)}
                    >
                        Last
                    </button>
                </div>
            </div>
            <div className="col-auto text-end">
                <div className="input-group">
                    <span className="input-group-text">
                        <i className="fa fa-filter"></i>
                    </span>
                    <select
                        value={limit}
                        onChange={(e) => setLimit(e.target.value)}
                        style={{ border: '1px solid lightgray' }}
                    >
                        <option value={10}>10 entries</option>
                        <option value={50}>50 entries</option>
                        <option value={100}>100 entries</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

export default PaginationComponent;