import React, { useEffect, useState } from 'react';
import { redirect, useNavigate } from 'react-router-dom';
import { getMasterAll, createForm } from '../API'
import Sweetalert from '../component/Sweetalert'
import Line from '../component/Line'
import CurrentInput from '../component/CurrentInput'
const MainFormCreateData = () => {
    const navigate = useNavigate();
    const storedUserProfile = JSON.parse(localStorage.getItem('auth'));
    const token = storedUserProfile.access_token
    const [masterdata, setmasterdata] = useState([]);
    const [loopcomponentcontract, setLoopcomponentcontract] = useState(1)
    const [loopcomponentpurchaseorder, setLoopcomponentpurchaseorder] = useState(1)
    const [loopcomponenttermofpayment, setLoopcomponenttermofpayment] = useState(1)
    const [loopcomponentinvoice, setLoopcomponentinvoice] = useState(1)
    const [loopcomponentaddition, setLoopcomponentaddition] = useState(1)
    const [companyid, setCompanyid] = useState(null)
    const [projectid, setProjectid] = useState(null)
    const [status, setStatus] = useState(null)
    const [titlecontract, setTitlecontract] = useState([])
    const [titlepurchaseorder, setTitlepurchaseorder] = useState([])
    const [titleinvoice, setTitleinvoice] = useState([])
    const [titleadditional, setTitleadditional] = useState([])
    const [percentage, setPercentage] = useState([])
    const [amount, setAmount] = useState([])
    const [filecontract, setFilecontract] = useState([null]);
    const [filepurchaseorder, setFilepurchaseorder] = useState([null]);
    const [fileinvoice, setFileinvoice] = useState([null]);
    const [fileaddtinal, setFileadditional] = useState([null]);
    const [countpercentage, setcountPercentage] = useState(0);
    const [countamount, setCountamount] = useState(0);
    const [warningPersentage, setWarningPersentage] = useState(false);


    const fetchMaster = async () => {
        const getData = await getMasterAll({ token: token })
        // console.log(getData)
        if (getData?.data?.message === 'success') {
            setmasterdata(getData?.data?.data)
        } else {
            Sweetalert({ icon: 'error', title: getData?.data?.message || 'Get Failed' });
        }
    }


    const formatRupiah = (number) => {
        // Menghapus semua karakter selain angka
        const numberString = number.toString();
        const split = numberString.split(',');
        const remainder = split[0].length % 3;
        let rupiah = split[0].substr(0, remainder);
        const thousands = split[0].substr(remainder).match(/\d{3}/gi);

        // Memasukkan titik setiap ribuan
        if (thousands) {
            const separator = remainder ? '.' : '';
            rupiah += separator + thousands.join('.');
        }

        rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
        return 'Rp ' + rupiah;
    };

    // set array title contract
    const handleTitleContactChange = (index, event) => {
        const newTitles = [...titlecontract];
        newTitles[index] = event.target.value;
        setTitlecontract(newTitles);
    };

    // set array title purchase order
    const handleTitlePurchaseOrderChange = (index, event) => {
        const newTitles = [...titlepurchaseorder];
        newTitles[index] = event.target.value;
        setTitlepurchaseorder(newTitles);
    };

    // set array title percentage
    const handlePercentageChange = (index, event) => {
        const newTitles = [...percentage];
        newTitles[index] = event.target.value;
        setPercentage(newTitles);
        if (newTitles.reduce((accumulator, value) => accumulator + Number(value), 0) > 100) {
            // alert jika persen > 100
            setWarningPersentage(true)
        } else {
            setcountPercentage(newTitles.reduce((accumulator, value) => accumulator + Number(value), 0))
        }

    }

    const handleAmountChange = (index, event) => {
        const newTitles = [...amount];
        newTitles[index] = event;
        setAmount(newTitles);

        setCountamount(newTitles.reduce((accumulator, value) => accumulator + Number(value?.replace(/[^0-9]/g, '')), 0));
    };

    const handleAddtionalChange = (index, event) => {
        const newTitles = [...titleadditional];
        newTitles[index] = event.target.value;
        setTitleadditional(newTitles);

    };

    const handleInvoiceChange = (index, event) => {
        const newTitles = [...titleinvoice];
        newTitles[index] = event.target.value;
        setTitleinvoice(newTitles);

    };

    const handleFileUploadContract = (index, event) => {
        const newFiles = [...filecontract];
        newFiles[index] = event.target.files[0];
        setFilecontract(newFiles);
    };

    const handleFileUploadPurchaseOrder = (index, event) => {
        const newFiles = [...filepurchaseorder];
        newFiles[index] = event.target.files[0];
        setFilepurchaseorder(newFiles);
    };

    const handleFileUploadInvoice = (index, event) => {
        const newFiles = [...fileinvoice];
        newFiles[index] = event.target.files[0];
        setFileinvoice(newFiles);
    };
    const handleFileUploadAdditional = (index, event) => {
        const newFiles = [...fileaddtinal];
        newFiles[index] = event.target.files[0];
        setFileadditional(newFiles);
    };

    const handleSave = async () => {

        // // Validation for required fields
        if (!companyid) return Sweetalert({ icon: 'error', title: 'Company Is Required' });
        if (!projectid) return Sweetalert({ icon: 'error', title: 'Project Is Required' });

        // Validate titlecontract and filecontract arrays
        if (titlecontract.length === 0 || filecontract.length === 0) {
            return Sweetalert({ icon: 'error', title: 'Contract Title and File Are Required' });
        }
        if (titlecontract.length !== filecontract.length) {
            return Sweetalert({ icon: 'error', title: 'Mismatch between Contract Titles and Files' });
        }
        for (let i = 0; i < titlecontract.length; i++) {
            if (!titlecontract[i]) {
                return Sweetalert({ icon: 'error', title: `Contract Title at position ${i + 1} is required` });
            }
            if (!filecontract[i]) {
                return Sweetalert({ icon: 'error', title: `Contract File at position ${i + 1} is required` });
            }
        }

        // Validate titlepurchaseorder and filepurchaseorder arrays
        if (titlepurchaseorder.length === 0 || filepurchaseorder.length === 0) {
            return Sweetalert({ icon: 'error', title: 'Purchase Order Title and File Are Required' });
        }
        if (titlepurchaseorder.length !== filepurchaseorder.length) {
            return Sweetalert({ icon: 'error', title: 'Mismatch between Purchase Order Titles and Files' });
        }
        for (let i = 0; i < titlepurchaseorder.length; i++) {
            if (!titlepurchaseorder[i]) {
                return Sweetalert({ icon: 'error', title: `Purchase Order Title at position ${i + 1} is required` });
            }
            if (!filepurchaseorder[i]) {
                return Sweetalert({ icon: 'error', title: `Purchase Order File at position ${i + 1} is required` });
            }
        }

        // Validate percentage and amount arrays
        if (percentage.length === 0 || amount.length === 0) {
            return Sweetalert({ icon: 'error', title: 'Termin Percentage and Amount Are Required' });
        }
        if (percentage.length !== amount.length) {
            return Sweetalert({ icon: 'error', title: 'Mismatch between Termin Percentages and Amounts' });
        }
        for (let i = 0; i < percentage.length; i++) {
            if (!percentage[i]) {
                return Sweetalert({ icon: 'error', title: `Termin Percentage at position ${i + 1} is required` });
            }
            if (!amount[i]) {
                return Sweetalert({ icon: 'error', title: `Termin Amount at position ${i + 1} is required` });
            }
        }


        // Validate titleinvoice and fileinvoice arrays
        if (titleinvoice.length === 0 || fileinvoice.length === 0) {
            return Sweetalert({ icon: 'error', title: 'Invoice Title and File Are Required' });
        }
        if (titleinvoice.length !== fileinvoice.length) {
            return Sweetalert({ icon: 'error', title: 'Mismatch between Invoice Titles and Files' });
        }
        for (let i = 0; i < titleinvoice.length; i++) {
            if (!titleinvoice[i]) {
                return Sweetalert({ icon: 'error', title: `Invoice Title at position ${i + 1} is required` });
            }
            if (!fileinvoice[i]) {
                return Sweetalert({ icon: 'error', title: `Invoice File at position ${i + 1} is required` });
            }
        }

        // Validate titleadditional and fileadditional arrays
        if (titleadditional.length === 0 || fileaddtinal.length === 0) {
            return Sweetalert({ icon: 'error', title: 'Additional Title and File Are Required' });
        }
        if (titleadditional.length !== fileaddtinal.length) {
            return Sweetalert({ icon: 'error', title: 'Mismatch between Additional Titles and Files' });
        }
        for (let i = 0; i < titleadditional.length; i++) {
            if (!titleadditional[i]) {
                return Sweetalert({ icon: 'error', title: `Additional Title at position ${i + 1} is required` });
            }
            if (!fileaddtinal[i]) {
                return Sweetalert({ icon: 'error', title: `Additional File at position ${i + 1} is required` });
            }
        }

        if (!status) return Sweetalert({ icon: 'error', title: 'Status Is Required' });


        let formData = new FormData();

        formData.append('company_id', companyid);
        formData.append('project_id', projectid);

        for (let a = 0; a < titlepurchaseorder.length; a++) {
            formData.append(`purchase_order_title[${a}]`, titlepurchaseorder[a]);
            formData.append(`purchase_order_file`, filepurchaseorder[a]);
        }

        for (let b = 0; b < titlecontract.length; b++) {
            formData.append(`contract_title[${b}]`, titlecontract[b]);
            formData.append(`contract_file`, filecontract[b]);
        }

        for (let c = 0; c < titleinvoice.length; c++) {
            formData.append(`invoice_title[${c}]`, titleinvoice[c]);
            formData.append(`invoice_file`, fileinvoice[c]);
        }

        for (let d = 0; d < titleadditional.length; d++) {
            formData.append(`additional_title[${d}]`, titleadditional[d]);
            formData.append(`additional_file`, fileaddtinal[d]);
        }


        for (let e = 0; e < percentage.length; e++) {
            formData.append(`termin_percentage[${e}]`, percentage[e]);
            formData.append(`termin_amount[${e}]`, amount[e]);
        }

        formData.append(`status`, status);
        try {
            const postData = await createForm({ token: token, payload: formData })

            if (postData?.data?.message === 'success') {
                Sweetalert({ icon: 'success', title: 'Create Data Success' });
                setCompanyid(null)
                setProjectid(null)
                setStatus(null)
                setTitlecontract([])
                setTitlepurchaseorder([])
                setTitleinvoice([])
                setTitleadditional([])
                setPercentage([])
                setAmount([])
                setFilecontract([null]);
                setFilepurchaseorder([null]);
                setFileinvoice([null]);
                setFileadditional([null]);
                setcountPercentage(0);
                setCountamount(0);
                navigate('/dashboard');
            } else {
                Sweetalert({ icon: 'error', title: postData?.data?.message || 'Create Datafailed' });
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.status === 400) {
                    Sweetalert({ icon: 'error', title: error.response.data.message });
                } else {
                    Sweetalert({ icon: 'error', title: `Error: ${error.response.status}` });
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.log('No response received: ', error.request);
                Sweetalert({ icon: 'error', title: 'No response from server' });
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error in request setup: ', error.message);
                Sweetalert({ icon: 'error', title: 'Request setup error' });
            }
        }

    }

    const renderFileInputsContract = () => {
        const inputs = [];
        for (let a = 0; a < loopcomponentcontract; a++) {
            inputs.push(
                <div className='row mb-3' key={a}>
                    <div className='col-md-6'>
                        <input type='text' className='form-control' required onChange={(event) => handleTitleContactChange(a, event)} placeholder='title' />
                    </div>

                    <div className='col-md-5'>
                        <input type='file' className='form-control' onChange={(event) => handleFileUploadContract(a, event)} />
                    </div>
                    {a === loopcomponentcontract - 1 && (
                        <div className='col-md-1  d-flex justify-content-between'>
                            <button type="button" class="btn btn-info waves-effect waves-light m-auto" onClick={() => setLoopcomponentcontract(loopcomponentcontract + 1)}>+</button>
                            {a > 0 && (
                                <button
                                    type="button"
                                    className="btn btn-danger waves-effect waves-light"
                                    onClick={() => {
                                        setLoopcomponentcontract(loopcomponentcontract - 1);
                                        setTitlecontract(titlecontract.slice(0, -1));
                                        setFilecontract(filecontract.slice(0, -1));
                                    }}
                                >
                                    -
                                </button>
                            )}
                        </div>
                    )}

                </div>
            );
        }
        return inputs;
    };
    const renderFileInputsPurchaseOrder = () => {
        const inputsPurchase = [];
        for (let b = 0; b < loopcomponentpurchaseorder; b++) {
            inputsPurchase.push(
                <div className='row mb-3' key={b}>
                    <div className='col-md-6'>
                        <input type='text' className='form-control' required onChange={(event) => handleTitlePurchaseOrderChange(b, event)} placeholder='title' />
                    </div>

                    <div className='col-md-5'>
                        <input type='file' className='form-control' onChange={(event) => handleFileUploadPurchaseOrder(b, event)} />
                    </div>
                    {b === loopcomponentpurchaseorder - 1 && (
                        <div className='col-md-1  d-flex justify-content-between'>
                            <button type="button" class="btn btn-info waves-effect waves-light m-auto" onClick={() => setLoopcomponentpurchaseorder(loopcomponentpurchaseorder + 1)}>+</button>
                            {b > 0 && (
                                <button type="button" class="btn btn-danger waves-effect waves-light"
                                    onClick={() => {
                                        setLoopcomponentpurchaseorder(loopcomponentpurchaseorder - 1)
                                        setTitlepurchaseorder(titlepurchaseorder.slice(0, -1));
                                        setFilepurchaseorder(filepurchaseorder.slice(0, -1));

                                    }}>-</button>
                            )}
                        </div>
                    )}

                </div>
            );
        }
        return inputsPurchase;
    };
    const renderFileInputsTermOfPayment = () => {
        const inputsTermOfPayment = [];
        for (let c = 0; c < loopcomponenttermofpayment; c++) {
            inputsTermOfPayment.push(
                <>
                    <div className='row mb-3' key={c}>
                        <div className='col-md-1'>
                            <label for="validationCustom02" class="form-label p-1">{`Termin ${c + 1}`}</label>
                        </div>
                        <div className='col-md-5'>
                            <input type='number' min={1} max={100} required className='form-control' placeholder='percentage' onChange={(event) => handlePercentageChange(c, event)} />
                        </div>
                        <div className='col-md-5'>
                            <CurrentInput
                                isChange={(newValue) => handleAmountChange(c, newValue)}
                            />
                        </div>


                        {c === loopcomponenttermofpayment - 1 && (
                            <div className='col-md-1  d-flex justify-content-between'>
                                <button type="button" required class="btn btn-info waves-effect waves-light m-auto" onClick={() => setLoopcomponenttermofpayment(loopcomponenttermofpayment + 1)}>+</button>
                                {c > 0 && (
                                    <button type="button" class="btn btn-danger waves-effect waves-light m-auto" onClick={() => {
                                        setLoopcomponenttermofpayment(loopcomponenttermofpayment - 1)
                                        if (percentage.length > 1) {
                                            setPercentage(percentage.slice(0, -1));
                                        }
                                        setAmount(amount.slice(0, -1));
                                    }}>-</button>
                                )}
                            </div>
                        )}

                    </div>
                </>
            );
        }
        return inputsTermOfPayment;
    };
    const renderFileInputsInvoice = () => {
        const inputsInvoice = [];
        for (let d = 0; d < loopcomponentinvoice; d++) {
            inputsInvoice.push(
                <div className='row mb-3' key={d}>
                    <div className='col-md-6'>
                        <input type='text' className='form-control' required onChange={(event) => handleInvoiceChange(d, event)} placeholder='title' />
                    </div>

                    <div className='col-md-5'>
                        <input type='file' className='form-control' onChange={(event) => handleFileUploadInvoice(d, event)} />
                    </div>
                    {d === loopcomponentinvoice - 1 && (
                        <div className='col-md-1  d-flex justify-content-between'>
                            <button type="button" class="btn btn-info waves-effect waves-light m-auto" onClick={() => setLoopcomponentinvoice(loopcomponentinvoice + 1)}>+</button>
                            {d > 0 && (
                                <button type="button" class="btn btn-danger waves-effect waves-light" onClick={() => setLoopcomponentinvoice(loopcomponentinvoice - 1)}>-</button>
                            )}
                        </div>
                    )}

                </div>
            );
        }
        return inputsInvoice;
    };
    const renderFileInputsAddition = () => {
        const inputsAddition = [];
        for (let e = 0; e < loopcomponentaddition; e++) {
            inputsAddition.push(
                <div className='row mb-3' key={e}>
                    <div className='col-md-6'>
                        <input type='text' className='form-control' required onChange={(event) => handleAddtionalChange(e, event)} placeholder='title' required />
                    </div>

                    <div className='col-md-5'>
                        <input type='file' className='form-control' onChange={(event) => handleFileUploadAdditional(e, event)} required />
                    </div>
                    {e === loopcomponentaddition - 1 && (
                        <div className='col-md-1  d-flex justify-content-between'>
                            <button type="button" class="btn btn-info waves-effect waves-light m-auto" onClick={() => setLoopcomponentaddition(loopcomponentaddition + 1)}>+</button>
                            {e > 0 && (
                                <button type="button" class="btn btn-danger waves-effect waves-light" onClick={() => setLoopcomponentaddition(loopcomponentaddition - 1)}>-</button>
                            )}
                        </div>
                    )}

                </div>
            );
        }
        return inputsAddition;
    };


    useEffect(() => {
        setcountPercentage(percentage.reduce((accumulator, value) => accumulator + Number(value), 0))
        setCountamount(amount.reduce((accumulator, value) => accumulator + Number(value?.replace(/[^0-9]/g, '')), 0));
        fetchMaster()
    }, [percentage, amount])

    return (
        <div class="main-content">
            <div className="page-content">
                <div className="page-title-box">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <div className="page-title">
                                    <h4>Form</h4>
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <a href="javascript: void(0);">DMS</a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript: void(0);">Create Data</a>
                                        </li>

                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="page-content-wrapper">
                        <div class="col-xl-12">
                            <div class="card">
                                <div class="card-body">
                                    <form class="needs-validation" novalidate>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label for="validationCustom01" class="form-label">Company Name</label>
                                                    <select class="form-select" onChange={(e) => setCompanyid(e.target.value)} required>
                                                        <option selected disabled value="">Choose...</option>
                                                        {masterdata?.company_list?.map((valCompany) =>
                                                            <option value={valCompany?.id} >{valCompany?.company_name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label for="validationCustom02" class="form-label">Project Name</label>
                                                    <select class="form-select" onChange={(e) => setProjectid(e.target.value)} required>
                                                        <option selected disabled value="">Choose...</option>
                                                        {masterdata?.project_list?.map((valProject) =>
                                                            <option value={valProject?.id} >{valProject?.project_name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <Line />

                                        <label for="validationCustom01" class="form-label">Contract File</label>
                                        {renderFileInputsContract()}

                                        <Line />

                                        <label for="validationCustom01" class="form-label">Purchase Order File</label>
                                        {renderFileInputsPurchaseOrder()}

                                        <Line />

                                        <label for="validationCustom01" class="form-label">Term Of Payment</label>
                                        {renderFileInputsTermOfPayment()}

                                        <div className='row'>
                                            <div className='col-md-1'>
                                                <label for="validationCustom02" className="form-label p-1 mb-3">Total</label>
                                            </div>
                                            <div className='col-md-5'>
                                                <input value={`${countpercentage}%`} className='form-control' readOnly />
                                                <span className={warningPersentage ? 'd-blok text-danger' : 'd-none'}>Cannot be more than 100%</span>
                                            </div>
                                            <div className='col-md-5'>
                                                <input value={formatRupiah(countamount)} className='form-control' readOnly />
                                            </div>
                                        </div>

                                        <Line />

                                        <label for="validationCustom01" className="form-label">Invoice</label>
                                        {renderFileInputsInvoice()}

                                        <Line />

                                        <label for="validationCustom01" className="form-label">Addition</label>
                                        {renderFileInputsAddition()}

                                        <Line />
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <label for="validationCustom02" className="form-label p-1">Status</label>
                                                <select className="form-select" onChange={(e) => setStatus(e.target.value)} required>
                                                    <option selected disabled value="">Choose...</option>
                                                    <option value="done">Done</option>
                                                    <option value="on progress">On Progress</option>
                                                </select>
                                            </div>
                                        </div>


                                        <div className='d-flex justify-content-end mt-3'>
                                            <button type="button" class="btn btn-outline-danger waves-effect waves-light mx-2" onClick={() => navigate('/dashboard')}>Cencel</button>
                                            <button type="button" class="btn btn-outline-success waves-effect waves-light" onClick={() => handleSave()}>Save</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MainFormCreateData;