
import React, { useEffect, useState } from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';
import Sidebar from '../component/Sidebar';
import MainMasterCompany from '../component/MainMasterCompany';
const MasterCompany = () => {
    return (
        <div id="layout-wrapper" >
            <Header />
            <Sidebar />
            <MainMasterCompany />
            <Footer />
        </div>
    )

}

export default MasterCompany;