import React from 'react';

const ModelCreate = ({ isOpen, onClose, onSave, setName }) => {
    if (!isOpen) return null; // If modal is not open, return null to render nothing

    return (
        <>
            {/* Backdrop element */}
            <div className="modal-backdrop fade show"></div>

            {/* Modal */}
            <div className="modal fade show" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" style={{ display: 'block' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mt-0">Create Data</h5>
                            <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <input type='text' required className='form-control' placeholder='Company Name' onChange={(valText) => setName(valText.target.value)} />

                            <div className='d-flex justify-content-end mt-3'>
                                <div className='d-flex justify-content-between'>
                                    <div className='p-1'>
                                        <a className='btn btn-sm btn-outline-success' onClick={onSave}>save</a>
                                    </div>
                                    <div className='p-1'>
                                        <a className='btn btn-sm btn-outline-danger' onClick={onClose}> Cencel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default ModelCreate;
