import axios from 'axios';
const apiUrl = process.env.REACT_APP_BASE_URL_BE;


export const postLogin = async (data) => {
    try {

        const response = await axios({
            method: 'post',
            url: `${apiUrl}auth/login`,
            data: data
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
}

export const getDataProject = async (data) => {
    try {

        const response = await axios({
            method: 'get',
            url: `${apiUrl}maker/form?limit=${data?.limit}&paginate=${data?.paginate}&company_id=&project_id=&status&sort=desc`,
            headers: {
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const createForm = async (data) => {
    try {

        const response = await axios({
            method: 'post',
            url: `${apiUrl}maker/form`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            },
            data: data?.payload
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const getMasterAll = async (data) => {
    try {

        const response = await axios({
            method: 'get',
            url: `${apiUrl}maker/masterall`,
            headers: {
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const syncForm = async (data) => {
    try {

        const response = await axios({
            method: 'post',
            url: `${apiUrl}maker/form_synchronize`,
            headers: {
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            },
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const deletedForm = async (data) => {
    try {

        const response = await axios({
            method: 'delete',
            url: `${apiUrl}maker/form/${data?.dataId}`,
            headers: {
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const detailForm = async (data) => {
    try {

        const response = await axios({
            method: 'get',
            url: `${apiUrl}maker/form_detail/${data?.formId}`,
            headers: {
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const getMasterCompany = async (data) => {
    try {

        const response = await axios({
            method: 'get',
            url: `${apiUrl}maker/master/master_company?paginate=${data?.paginate}&limit=${data?.limit}&key_search=${data.keysearchs}&sort=asc`,
            headers: {
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const createMasterCompany = async (data) => {
    try {

        const response = await axios({
            method: 'post',
            url: `${apiUrl}maker/master/master_company`,
            data: data?.payload,
            headers: {
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const editMasterCompany = async (data) => {
    try {

        const response = await axios({
            method: 'put',
            url: `${apiUrl}maker/master/master_company/${data?.dataId}`,
            data: data?.payload,
            headers: {
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const deleteMasterCompany = async (data) => {
    try {

        const response = await axios({
            method: 'delete',
            url: `${apiUrl}maker/master/master_company/${data?.dataId}`,
            data: data?.payload,
            headers: {
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};


export const createMasterProject = async (data) => {
    try {

        const response = await axios({
            method: 'post',
            url: `${apiUrl}maker/master/master_project`,
            data: data?.payload,
            headers: {
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const getMasterProject = async (data) => {
    try {

        const response = await axios({
            method: 'get',
            url: `${apiUrl}maker/master/master_project?paginate=${data?.paginate}&limit=${data?.limit}&key_search=${data.keysearchs}&sort=asc`,
            headers: {
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const getMasterProjectForAdmin = async (data) => {
    try {

        const response = await axios({
            method: 'get',
            url: `${apiUrl}admin/form?paginate=${data?.paginate}&limit=${data?.limit}&key_search=${data.keysearchs}&sort=asc`,
            headers: {
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const editMasterProject = async (data) => {
    try {

        const response = await axios({
            method: 'put',
            url: `${apiUrl}maker/master/master_project/${data?.dataId}`,
            data: data?.payload,
            headers: {
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
}

export const deleteMasterProject = async (data) => {
    try {

        const response = await axios({
            method: 'delete',
            url: `${apiUrl}maker/master/master_project/${data?.dataId}`,
            data: data?.payload,
            headers: {
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const deleteByModul = async (data) => {
    try {

        // console.log(data.form_id, 'FE')
        // console.log(`${apiUrl}maker/form_detail/${data?.modul}?form_id=${data?.form_id}&data_id=${data?.data_id}`, 'url')
        const response = await axios({
            method: 'delete',
            url: `${apiUrl}maker/form_detail/${data?.modul}?form_id=${data?.form_id}&data_id=${data?.data_id}`,
            data: data?.payload,
            headers: {
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const updateForm = async (data) => {
    try {
        const response = await axios({
            method: 'put',
            url: `${apiUrl}maker/form/${data?.formId}`,
            data: data?.payload,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const updateFileById = async (data) => {
    try {
        const response = await axios({
            method: 'put',
            url: `${apiUrl}maker/form_detail_file/${data?.modul}?form_id=${data?.formId}&data_id=${data?.dataId}`,
            data: data?.payload,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const updateTitleById = async (data) => {
    try {

        const response = await axios({
            method: 'put',
            url: `${apiUrl}maker/form_detail_title/${data?.modul}?form_id=${data?.formId}&data_id=${data?.dataId}`,
            data: data?.payload,
            headers: {
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const detailFormAdmin = async (data) => {
    try {

        const response = await axios({
            method: 'get',
            url: `${apiUrl}admin/form_detail/${data?.formId}`,
            headers: {
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const getMasterAllAdmin = async (data) => {
    try {

        const response = await axios({
            method: 'get',
            url: `${apiUrl}admin/masterall`,
            headers: {
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const syncFormAdmin = async (data) => {
    try {

        const response = await axios({
            method: 'post',
            url: `${apiUrl}admin/form_synchronize`,
            headers: {
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            },
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const getUserList = async (data) => {
    try {

        const response = await axios({
            method: 'get',
            url: `${apiUrl}admin/users?paginate=${data?.paginate}&limit=${data?.limit}&key_search=${data.keysearchs}&sort=asc`,
            headers: {
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            },
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const createUser = async (data) => {
    try {

        const response = await axios({
            method: 'post',
            url: `${apiUrl}admin/users`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            },
            data: data?.payload
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const deleteUser = async (data) => {
    try {

        const response = await axios({
            method: 'delete',
            url: `${apiUrl}admin/users/${data?.dataId}`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${data?.token}` // Menambahkan token ke header
            },
            data: data?.payload
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};