import React, { useEffect, useState } from 'react';
import { getUserList, createUser, editMasterProject, getMasterAllAdmin } from '../API'
import Sweetalert from './Sweetalert'
import ModalCreateUsers from './ModalCreateUsers'
import ModalEdit from './ModalEdit'
import SweetalertConfirm from './SweetalertConfirm'
import PaginationComponent from './PaginationComponent'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
const MainMasterUser = () => {
    const navigate = useNavigate();
    const storedUserProfile = JSON.parse(localStorage.getItem('auth'));
    const token = storedUserProfile.access_token
    const [masterdata, setmasterdata] = useState([]);
    const [data, setData] = useState([])
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState('10');
    const [keysearch, setKeysearch] = useState('');
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [username, setUsername] = useState(null);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [roles, setRoles] = useState(null);
    const [photo, setPhoto] = useState([null]);
    const [projectDataEdit, setProjectDataEdit] = useState(null);



    // fetch list
    const fetchList = async () => {
        const getData = await getUserList({ token: token, limit: limit, paginate: page, keysearchs: keysearch })
        if (getData?.data?.message === 'success') {
            setData(getData?.data?.data)
        } else {
            Sweetalert({ icon: 'error', title: getData?.data?.message || 'Get Failed' });
        }
    }

    const fetchMaster = async () => {
        const getData = await getMasterAllAdmin({ token: token })
        // console.log(getData)
        if (getData?.data?.message === 'success') {
            setmasterdata(getData?.data?.data)
        } else {
            Sweetalert({ icon: 'error', title: getData?.data?.message || 'Get Failed' });
        }
    }


    // delete row
    const handleDeleteMaster = async (data) => {
        SweetalertConfirm({ type: 'deletedusers', token: token, dataId: data })
        fetchList()
    }

    // handle modal create
    const handleToggleModalCreate = () => {
        setIsModalCreateOpen(!isModalCreateOpen)
    }


    // create row
    const handleSaveMaster = async () => {
        setIsModalCreateOpen(false)

        let formData = new FormData();

        formData.append('username', username);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('roles_id', roles);
        formData.append('user_photo', photo);


        try {
            const postMaster = await createUser({ token: token, payload: formData })

            if (postMaster?.data?.message === 'success') {
                Sweetalert({ icon: 'success', title: 'Create Data Success' });
                fetchList()
            } else {
                Sweetalert({ icon: 'error', title: postMaster?.data?.message || 'Create Data failed' });
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.status === 400) {
                    Sweetalert({ icon: 'error', title: error.response.data.message });
                } else {
                    Sweetalert({ icon: 'error', title: `Error: ${error.response.status}` });
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.log('No response received: ', error.request);
                Sweetalert({ icon: 'error', title: 'No response from server' });
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error in request setup: ', error.message);
                Sweetalert({ icon: 'error', title: 'Request setup error' });
            }
        }

    }


    useEffect(() => {
        fetchList()
        fetchMaster()
    }, [page, limit, keysearch])



    return (
        <div class="main-content">
            <div className="page-content">
                <div className="page-title-box">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <div className="page-title">
                                    <h4>Data Project</h4>
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <a href="javascript: void(0);">DMS</a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript: void(0);">Data Project</a>
                                        </li>

                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="page-content-wrapper">

                        <div class="col-xl-12">
                            <div class="card">
                                <div class="card-body">
                                    <div className='d-flex justify-content-end m-auto'>
                                        <div className='p-2'>
                                            <div class="input-group input-group-sm" id="datepicker1">
                                                <input className='form-control-sm' placeholder='search' onChange={(event) => setKeysearch(event.target.value)} />

                                                <span class="input-group-text" style={{ backgroundColor: '#ff' }}><i class="mdi mdi-database-search"></i></span>
                                            </div>
                                        </div>
                                        <div className='p-2'>
                                            <a className='btn btn-outline-info btn-sm mb-2' onClick={() => handleToggleModalCreate()}>+ Create New Data</a>
                                        </div>
                                    </div>
                                    <table class="table table-bordered dt-responsive nowrap" style={{ borderCollapse: 'collapse', borderSpacing: '0', width: '100%' }}>
                                        <thead>
                                            <tr className='text-white text-center' style={{ backgroundColor: '#579ec5' }}>
                                                <th width='10'>No</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Roles</th>
                                                <th>Status</th>
                                                <th>#</th>
                                            </tr>
                                        </thead>


                                        <tbody>
                                            {data?.users_list?.map((item, index) =>
                                                <tr>
                                                    <td>{(page - 1) * limit + index + 1}</td>
                                                    <td>{item?.username}</td>
                                                    <td>{item?.email}</td>
                                                    <td>{item?.roles_data?.roles_name}</td>
                                                    <td> <button className={`btn btn-sm ${item?.is_active == true ? 'btn-outline-success' : 'btn-outline-danger'} `}>{item?.is_active == true ? 'active' : 'unactive'}</button></td>
                                                    <td>
                                                        <div className='d-flex justify-content-center'>
                                                            <a className='btn btn-outline-danger btn-sm'
                                                                onClick={() => handleDeleteMaster(item.id)}
                                                            >
                                                                <i className='fas fa-trash-alt'></i>
                                                            </a>
                                                        </div>
                                                    </td>

                                                </tr>

                                            )}
                                        </tbody>
                                    </table>

                                    <div className='d-flex justify-content-end'>
                                        <PaginationComponent
                                            page={page}
                                            dataList={data}
                                            setPage={setPage}
                                            limit={limit}
                                            setLimit={setLimit}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal Component */}
                {isModalCreateOpen && (
                    <ModalCreateUsers isOpen={isModalCreateOpen} onClose={handleToggleModalCreate} onSave={handleSaveMaster} setUsername={setUsername} setPassword={setPassword} setPhoto={setPhoto} setRoles={setRoles} setEmail={setEmail} masterData={masterdata?.roles_list} />
                )}

            </div>
        </div >


    )
}

export default MainMasterUser;